import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { LocaleLinkQuery } from '../../types/graphql-types';
import { usePageContext } from '../../hooks/context/usePageContext';

interface ISmartLinkProps {
  to: string | undefined | null;
  children: React.ReactNode;
  className?: string;
}

export const LocaleLink = ({ to, children, className }: ISmartLinkProps) => {
  const { lang } = usePageContext();
  const { site } = useStaticQuery<LocaleLinkQuery>(QUERY);
  const defaultLang = site?.siteMetadata?.defaultLanguage;

  const slug = to === '/' ? '' : to ?? '';
  const localeSlug = lang === defaultLang ? `/${slug}` : `/${lang}/${slug}`;

  return (
    <Link to={localeSlug} className={className}>
      {children}
    </Link>
  );
};

const QUERY = graphql`
  query LocaleLink {
    site {
      siteMetadata {
        defaultLanguage
      }
    }
  }
`;
