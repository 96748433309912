import React from 'react';
import { DeviceEnum } from '../../utils/enums';
import { NavbarMenu } from '../navbarMenu/NavbarMenu';
import * as styles from './navbarDesktop.module.scss';

export const NavbarDesktop = () => (
  <nav className={styles.wrapper}>
    <NavbarMenu device={DeviceEnum.desktop} />
  </nav>
);
