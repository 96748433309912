import React, { useContext, createContext, useMemo, useCallback } from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/toastify.scss';

type SetSnackBarType = (message: string, status: 'ok' | 'ko') => void;

type ISnackBarContext = {
  setSnackBar: SetSnackBarType;
};

const SnackBarContext = createContext<ISnackBarContext | undefined>(undefined);

export const SnackBarContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const setSnackBar = useCallback<SetSnackBarType>(
    (message, status) => {
      const commonConf: ToastOptions = {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      };
      switch (status) {
        case 'ok':
          toast.success(message, { ...commonConf });
          break;
        case 'ko':
          toast.error(message, { ...commonConf });
          break;
        default:
          toast.info(message, { ...commonConf });
      }
    },

    []
  );
  const value = useMemo(() => ({ setSnackBar }), [setSnackBar]);
  return (
    <SnackBarContext.Provider value={value}>
      <ToastContainer />
      {children}
    </SnackBarContext.Provider>
  );
};

export const useSnackBarContext = () => {
  const snackBarContext = useContext(SnackBarContext);

  if (!snackBarContext)
    throw new Error('No SnackBarContext.Provider found when calling useSnackBarContext.');
  return snackBarContext;
};
