import React from 'react';
import ArrowTop from '../../../static/svg/arrowTop.svg';
import * as styles from './fixedMobileMenu.module.scss';

interface IFixedProps {
  setOpen: (open: boolean) => void;
  open: boolean;
}

export const FixedMobileMenu = ({ setOpen, open }: IFixedProps) => (
  <div className={styles.wrapper}>
    <button onClick={() => setOpen(!open)} className={styles.button} type="button">
      <div>menu</div>
      <div className={!open ? styles.backFlip : styles.flip}>
        <ArrowTop />
      </div>
    </button>
  </div>
);
