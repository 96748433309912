import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { usePageContext } from '../../hooks/context/usePageContext';
import { LangsQuery } from '../../types/graphql-types';
import * as styles from './languageToggle.module.scss';

const LanguageToggle = () => {
  const { lang, setLang } = usePageContext();
  const { site } = useStaticQuery<LangsQuery>(queryLang);

  const defaultLanguage = site?.siteMetadata?.defaultLanguage;
  const secondLanguage = site?.siteMetadata?.extraLanguages?.[0];

  return (
    <button
      type="button"
      className={styles.languageToggle}
      onClick={() => setLang((lang === defaultLanguage ? secondLanguage : defaultLanguage) ?? '')}
    >
      {[defaultLanguage, secondLanguage].map((elem) => (
        <div key={elem} className={`${styles.language} ${lang !== elem ? styles.selected : ''}`}>
          {elem}
        </div>
      ))}
      <div className={`${styles.circle} ${lang !== defaultLanguage ? styles.selected : ''}`} />
    </button>
  );
};

const queryLang = graphql`
  query Langs {
    site {
      siteMetadata {
        defaultLanguage
        extraLanguages
      }
    }
  }
`;

export default LanguageToggle;
