import React from 'react';
import * as styles from './buttonLoader.module.scss';

// export enum ThemeButtonLoaderEnum {
//   grey = 'grey',
//   black = 'black',
// }

// interface IButtonLoaderProps {
//   theme?: ThemeButtonLoaderEnum;
// }

// ISSUE 545 => handle theme loader
const ButtonLoader = () => <div className={styles.loader}>loading</div>;

export default ButtonLoader;
