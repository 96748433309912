import React from 'react';
import * as styles from './lineSeparator.module.scss';

interface ILineSeparatorProps {
  color?: string;
  width?: string;
  height?: string;
}

export const LineSeparator = ({
  color = 'white',
  width = '100%',
  height = '1px',
}: ILineSeparatorProps) => {
  const styleFromProps = {
    width,
    borderTop: `solid ${height} ${color}`,
  };
  return <div className={styles.separator} style={styleFromProps} />;
};
