import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Button } from '../button/Button';
import { CalendlyModal } from '../calendlyModal/CalendlyModal';
import { CalendlyQuery } from '../../types/graphql-types';

import * as styles from './buttonCalendly.module.scss';

interface IButtonCalendlyProps {}

export const ButtonCalendly = ({}: IButtonCalendlyProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const data = useStaticQuery<CalendlyQuery>(QUERY);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 7000);
  }, []);

  return (
    <div className={`${styles.wrapper} ${visible ? styles.visible : styles.hide}`}>
      <button
        className={styles.mobile}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {data?.sanityCalendly?.label}
      </button>
      <div className={styles.desktop}>
        <Button
          onClick={() => {
            setModalOpen(true);
          }}
        >
          {data?.sanityCalendly?.label}
        </Button>
      </div>
      <CalendlyModal
        open={modalOpen}
        label={data?.sanityCalendly?.label ?? ''}
        url={data?.sanityCalendly?.calendlyUrl ?? ''}
        setOpen={setModalOpen}
      />
    </div>
  );
};

const QUERY = graphql`
  query Calendly {
    sanityCalendly {
      label
      calendlyUrl
    }
  }
`;
