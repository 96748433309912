import React, { useCallback, useEffect, useState } from 'react';
import * as styles from './navbar.module.scss';
import { NavbarDesktop } from '../navbarDesktop/NavbarDesktop';
import { LocaleLink } from '../localeLink/LocaleLink';
import { Logo } from '../logo/Logo';

export const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY ?? prevScrollPos;
    if (currentScrollPos > prevScrollPos && currentScrollPos > 0) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos]);

  useEffect(() => {
    if (window) window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      if (window) window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className={`${styles.wrapper} ${visible ? '' : styles.hide}`}>
      <NavbarDesktop />
      <div className={styles.mobile}>
        <LocaleLink to="/">
          <Logo />
        </LocaleLink>
      </div>
    </div>
  );
};
