import './src/styles/globals.scss';
import React from 'react';
import { Layout } from './src/layouts/Layout';
import { PageContextProvider } from './src/hooks/context/usePageContext';
// import { GoogleTagManagerHead } from './src/components/googleTagManager/GoogleTagManagerHead';

export const wrapPageElement = ({ element, props }) => (
  <>
    {/* <GoogleTagManagerHead /> */}
    <PageContextProvider pageContext={props.pageContext}>
      <Layout>{element}</Layout>
    </PageContextProvider>
  </>
);
