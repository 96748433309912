import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MenuQuery } from '../../types/graphql-types';
import { DeviceEnum } from '../../utils/enums';
import * as styles from './navbarMenu.module.scss';
import { usePageContext } from '../../hooks/context/usePageContext';
import { PageLink } from '../pageLink/PageLink';
import LanguageToggle from '../languageToggle/LanguageToggle';
import { LocaleLink } from '../localeLink/LocaleLink';
import { Logo } from '../logo/Logo';

interface INavbarMenuProps {
  device: DeviceEnum;
  open?: boolean;
}

const chooseClassname = (device: string, open: boolean | undefined) => {
  if (device === DeviceEnum.mobile && !open) return styles.hidden;
  return undefined;
};

export const NavbarMenu = ({ device, open }: INavbarMenuProps) => {
  const { lang } = usePageContext();
  const { sanityMenu } = useStaticQuery<MenuQuery>(queryMenu);

  return (
    <ul data-device={device} className={chooseClassname(device, open)}>
      <li>
        <LocaleLink to="/">
          <Logo />
        </LocaleLink>
      </li>
      {sanityMenu?.itemList?.map((menu) => (
        <li className={styles.menu} key={menu?._key}>
          <PageLink className={styles.link} pageId={menu?.page?.id}>
            {menu?._rawTitle?.[lang]}
          </PageLink>
        </li>
      ))}
      <li>
        <LanguageToggle />
      </li>
    </ul>
  );
};

const queryMenu = graphql`
  query Menu {
    sanityMenu {
      _key
      __typename
      itemList {
        _key
        _rawTitle
        page {
          id
          title
        }
      }
    }
  }
`;
