import React, { useEffect, useState } from 'react';
import { PopupModal } from 'react-calendly';

import * as styles from './calendlyModal.module.scss';

interface ICalendlyModalProps {
  url: string;
  label: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const CalendlyModal = ({ url, label, open, setOpen }: ICalendlyModalProps) => {
  const [isSsr, setIsSsr] = useState<boolean>(true);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') setIsSsr(false);
  }, []);

  if (isSsr) return null;

  const calendlyElement = document.getElementById('__calendly');

  if (!calendlyElement) return null;

  return (
    <div className={styles.wrapper}>
      <PopupModal
        url={url}
        rootElement={calendlyElement}
        open={open}
        onModalClose={() => setOpen(false)}
      />
    </div>
  );
};
