import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LocaleLink } from '../localeLink/LocaleLink';
import { usePageContext } from '../../hooks/context/usePageContext';
import { AllTranslationMetaQuery } from '../../types/graphql-types';

interface IPageLink {
  pageId?: string;
  children: React.ReactNode;
  className?: string;
}

export const PageLink = ({ pageId, children, className }: IPageLink) => {
  const { lang } = usePageContext();
  const { allSanityTranslationMetadata } = useStaticQuery<AllTranslationMetaQuery>(QUERY);

  const localePages = allSanityTranslationMetadata.nodes.find(
    (el) => el.translations?.find((page) => page?.value?.id === pageId)
  );
  const slug = localePages?.translations?.find((page) => page?.value?.lang === lang)?.value?.slug
    ?.current;

  return (
    <LocaleLink className={className} to={slug}>
      {children}
    </LocaleLink>
  );
};

const QUERY = graphql`
  query allTranslationMeta {
    allSanityTranslationMetadata {
      nodes {
        translations {
          value {
            _type
            id
            lang
            slug {
              current
            }
          }
        }
      }
    }
  }
`;
