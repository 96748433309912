import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LogoQuery } from '../../types/graphql-types';
import * as styles from './logo.module.scss';

export const Logo = () => {
  const { sanitySettings } = useStaticQuery<LogoQuery>(QUERY);
  const image = getImage(sanitySettings?.logo?.asset?.gatsbyImageData);

  if (!image) return null;

  return <GatsbyImage image={image} alt="210blocks" className={styles.image} />;
};

const QUERY = graphql`
  query Logo {
    sanitySettings {
      logo {
        asset {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  }
`;
