import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as styles from './footer.module.scss';
import LanguageToggle from '../languageToggle/LanguageToggle';
import { LineSeparator } from '../lineSeparator/LineSeparator';
import { FooterQuery } from '../../types/graphql-types';
import { usePageContext } from '../../hooks/context/usePageContext';
import { PageLink } from '../pageLink/PageLink';

export const Footer = () => {
  const { sanityFooter, sanityContact } = useStaticQuery<FooterQuery>(QUERY);
  const { lang, footerColor } = usePageContext();
  return (
    <footer className={`${styles.wrapper} ${footerColor === 'dark' ? styles.darkBg : ''}`}>
      <nav className={styles.menu}>
        <ul>
          {sanityFooter?.links?.map((link) => (
            <li key={link?._key}>
              <PageLink pageId={link?.page?.id}>{link?._rawTitle?.[lang]}</PageLink>
            </li>
          ))}
        </ul>
      </nav>

      <div className={styles.toggleContainer}>
        <LanguageToggle />
      </div>
      <div className={styles.lineSeparatorContainer}>
        <LineSeparator />
      </div>

      <div className={styles.contact}>
        <p>{sanityContact?.name}</p>
        <br />
        <p>{sanityContact?.email}</p>
        {/* <p>{sanityContact?.phone}</p> */}
        {sanityFooter?.socials && (
          <ul className={styles.logos}>
            {sanityFooter?.socials?.map((social) => {
              const image = getImage(social?.logo?.asset?.gatsbyImageData);
              return (
                social &&
                image && (
                  <li className={styles.logo} key={social?.id}>
                    <a target="_blank" rel="noreferrer" href={social?.href ?? '/'}>
                      <GatsbyImage image={image} alt={social?.title ?? ''} />
                    </a>
                  </li>
                )
              );
            })}
          </ul>
        )}
      </div>
    </footer>
  );
};

const QUERY = graphql`
  query Footer {
    sanityFooter {
      id
      links {
        _rawTitle
        _key
        page {
          id
        }
      }
      socials {
        id
        title
        logo {
          asset {
            gatsbyImageData
          }
        }
        href
      }
    }

    sanityContact {
      id
      name
      phone
      email
    }
  }
`;
