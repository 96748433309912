import React from 'react';
import ButtonLoader from '../buttonLoader/ButtonLoader';
import * as styles from './button.module.scss';
import ArrowRight from '../../../static/svg/arrowRight.svg';

export enum ThemeButtonEnum {
  black = 'black',
  orange = 'orange',
}

export enum ButtonTypeEnum {
  button = 'button',
  submit = 'submit',
}

interface IButtonProps {
  loading?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  theme?: ThemeButtonEnum;
  children: React.ReactNode;
  type?: ButtonTypeEnum;
  disabled?: boolean;
  id?: string;
}

/** if onClick isn't provided will only return button as styled div without a button tag wrapping it */
export const Button = ({
  onClick,
  theme = ThemeButtonEnum.black,
  loading = false,
  children,
  type = ButtonTypeEnum.button,
  disabled = false,
  id,
}: IButtonProps) => {
  const content = (
    <div
      className={`${styles.wrapper} ${loading ? styles.loading : ''} ${styles.liquidbg}`}
      data-theme={theme}
    >
      <span className={loading ? styles.hidden : ''}>{children}</span>
      {loading ? <ButtonLoader /> : <ArrowRight />}
    </div>
  );

  if (!onClick && type !== ButtonTypeEnum.submit) return content;

  return (
    <button
      id={id}
      type={type === ButtonTypeEnum.button ? 'button' : 'submit'}
      onClick={(e) => onClick && onClick(e)}
      disabled={disabled}
    >
      {content}
    </button>
  );
};
